<template>
	<div class="order_wrap px-6">
		<v-container class="">
			<div class="message_box mt-3">
				<span class="txt_body1">
					인증시간이 만료됐습니다.<br />
					계약을 진행하길 원하시는 경우
					<br />
					담당 판매인에게 연락 바랍니다.
				</span>
			</div>
		</v-container>
	</div>
</template>

<script>
/**
 * 인증 만료 화면
 */

export default {
	name: 'CustVerifyExpired'
}
</script>
